<template>
  <body-with-header ads title="دستورالعمل های زمین">
    <CRow style="height: 64dvh">
      <CCol md="4" class="h-100">
        <farms @GetTips="GetFarmTips" />
      </CCol>
      <CCol md="8" class="h-100">
        <tips :farmId="farmId" :farmerName="farmerName" :farmName="farmName" />
      </CCol>
    </CRow>
  </body-with-header>
</template>

<script>
import farms from "./subForm/farms";
import tips from "./subForm/tips";
import BodyWithHeader from "../../components/bodyWithHeader.vue";

export default {
  name: "advicerTips",
  components: {
    BodyWithHeader,
    farms,
    tips,
  },
  data() {
    return {
      farmId: 0,
      farmerName:
        "لیست تمام دستورالعمل های زمین های مرتبط با شما (برای ثبت دستورالعمل یک زمین را انتخاب کنید)",
      farmName: "",
    };
  },
  computed: {},
  methods: {
    GetFarmTips(farmId, farmer) {
      this.farmId = farmId;
      this.farmerName = `دستورالعمل های ثبت شده برای  -- ${farmer}`;
      this.farmName = farmer;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>

</style>
